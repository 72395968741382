import { createStore } from 'vuex'

export default createStore({
  state: {
    topNavSelectState:null, // 页面导航栏的选择 1.首页 2.产品与服务 3.华锐新闻 4.人才招聘 5.联系我们
  },
  getters: {
    getTopNavSelectState(state){
      return state.topNavSelectState
    }
  },
  mutations: {
    setTopNavSelectState(state,data){
      if(!isNaN(data)){
        state.topNavSelectState = data
      }else{
        alert("导航栏只能使用数字控制!")
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
