import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import Request from "@/static/js/axios.js"
import requesrFile from "@/static/js/fileAxios.js"

const app = createApp(App)
app.config.globalProperties.$http = Request
app.config.globalProperties.$httpFile = requesrFile
app .use(store).use(Antd).use(router).mount('#app')
