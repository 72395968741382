import axios from 'axios';
import qs from 'qs'; // 是一个增加了一些安全性的查询字符串解析和序列化字符串的库


axios.defaults.timeout = 5000; //请求失效的时间
// axios.defaults.baseURL = 'http://hwaru.1122334455.cn/'; //域名 若使用代理跨域无需配置此项

//解决跨域的方式叫做本地代理

// 请求前调用
axios.interceptors.request.use(
    config => {
        config.data = qs.stringify(config.data); //公共参数
        config.headers = { //配置请求头
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', //请求方式
        }
        return config;
    },
);


//响应拦截器即异常处理
//请求后调用
axios.interceptors.response.use(response => {
    // 请求成功调用
    // 可以在这里做一些公共参数的验证
    return response
}, err => {
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                console.log('错误请求')
                break;
            case 401:
                console.log('未授权，请重新登录')
                break;
            case 403:
                console.log('拒绝访问')
                break;
            case 404:
                console.log('请求错误,未找到该资源')
                break;
            case 405:
                console.log('请求方法未允许')
                break;
            case 408:
                console.log('请求超时')
                break;
            case 500:
                console.log('服务器端出错')
                break;
            case 501:
                console.log('网络未实现')
                break;
            case 502:
                console.log('网络错误')
                break;
            case 503:
                console.log('服务不可用')
                break;
            case 504:
                console.log('网络超时')
                break;
            case 505:
                console.log('http版本不支持该请求')
                break;
            default:
                console.log(`连接错误${err.response.status}`)
        }
    }
    return Promise.resolve(err.response)
})



export default {

    /**
     * 封装get方法
     * @param url
     * @param data
     * @returns {Promise}
     */
    // params = {}默认参数
    get: function (url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.get(url, { // Object.assign()
                params: Object.assign(params),
            }).then(response => {
                resolve(response.data);
            })
                .catch(err => {
                    reject(err)
                })
        })
    },

    /**
     * 封装post请求
     * @param url
     * @param data
     * @returns {Promise}
     */

    post: function (url, data = {}) {
        if(window.location.host.indexOf("localhost") != -1){ // 判断是否在本地
            url = "/api" + url
        }
        return new Promise((resolve, reject) => {
            axios.post(url, Object.assign(data))
                .then(response => {
                    if(response.data){
                    resolve(response.data);
                    }else{
                        console.log(url+"无返回值");
                    }
                }, err => {
                    reject(err)
                })
        })
    },

    /**
     * 封装patch请求
     * @param url
     * @param data
     * @returns {Promise}
     */

    patch: function (url, data = {}) {
        return new Promise((resolve, reject) => {
            axios.patch(url, data)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err)
                })
        })
    },

    /**
     * 封装put请求
     * @param url
     * @param data
     * @returns {Promise}
     */

    put: function (url, data = {}) {
        return new Promise((resolve, reject) => {
            axios.put(url, data)
                .then(response => {
                    resolve(response.data);
                }, err => {
                    reject(err)
                })
        })
    }
}