// util.js
import axios from 'axios'
// 封装axios，baseURL是一个基础路径
const util = {}
util.ajax = axios.create({
    baseURL: 'http://hwaru.1122334455.cn/'
})
// 把post原生请求变为formData
util.transParams = (data) => {
    let params = new FormData()
    params.append("image",data.image)
    params.append("token",data.token)
    return params
}

// 一般用请求头
util.headersUrlencoded = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
}
// 上传文件用请求头
util.headersMultipart = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
}
// 添加请求拦截器
util.ajax.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});
// 添加响应拦截器
util.ajax.interceptors.response.use(function (res) {
    return res
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

const API = {
    // post 请求
    post(url, data = {}) {
        // url = '/api' + url
        return util.ajax.post(url, util.transParams(data), util.headersUrlencoded); // 这里data必须传一个对象 对象中的image就是图片文件
    },
    // get 请求
    get(url, data = {}) {
        return util.ajax.get(url, {
            responseType: 'json',
            params: data
        })
    }
}
export default API