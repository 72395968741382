<template>
    <div id="rightNav">
        <!-- 内容侧边栏 -->
        <div class="contentBox">
            <div :class="contentSelectState == 1 ? 'isContent content one' : 'content one'"
                @click="setContentSelectState(1), setAllHidden(false), setFormState(true)">
                <img :src="contentSelectState == 1 ? require('@/assets/ce_icon_kefu_selected@2x.png') : require('@/assets/ce_icon_kefu_normal@2x.png')"
                    alt="">
                <span>客服</span>
            </div>
            <div :class="contentSelectState == 2 ? 'isContent content two' : 'content two'"
                @click="setContentSelectState(2), setAllHidden(true)">
                <img :src="contentSelectState == 2 ? require('@/assets/ce_icon_wechat_selected@2x.png') : require('@/assets/ce_icon_wechat_normal@2x.png')"
                    alt="">
                <span>微信</span>
                <!-- 微信 -->
                <div id="wxBox" :class="contentSelectState == 2 ? 'phoneIsShow' : 'phoneIsHidden'">
                    <img class="code" src="@/assets/code.jpg" alt="">
                </div>
            </div>
            <div :class="contentSelectState == 3 ? 'isContent content three' : 'content three'"
                @click="setContentSelectState(3), setAllHidden(true)">
                <img :src="contentSelectState == 3 ? require('@/assets/ce_icon_cal_selected@2x.png') : require('@/assets/ce_icon_cal_normal@2x.png')"
                    alt="">
                <span class="text">电话</span>
                <!-- 电话 -->
                <div id="phoneBox" :class="contentSelectState == 3 ? 'phoneIsShow' : 'phoneIsHidden'">
                    <span class="phone">020-85583123</span>
                </div>
            </div>
            <div :class="contentSelectState == 4 ? 'isContent content four' : 'content four'"
                @click="setContentSelectState(4), setAllHidden(false), goTop()">
                <img :src="contentSelectState == 4 ? require('@/assets/ce_icon_top_selected@2x.png') : require('@/assets/ce_icon_top_normal@2x.png')"
                    alt="">
                <span>顶部</span>
            </div>
        </div>
    </div>
    <!-- 取消导航栏所有选择 -->
    <div :class="allHidden ? 'isShowBack' : 'isHiddenBack'" @click="isAllHidden"></div>
    <!-- 客服 -->
    <Transition>
        <div id="serverBox" @click="setFormState(false), isAllHidden()" v-show="formState">
            <div class="serverFormBox" @click.stop="isNull">
                <div class="formTitle">提交信息</div>
                <div class="formTitleTwo">联系我们，体验优质服务</div>
                <div class="formBox">
                    <div class="inputBox">
                        <span>姓名</span>
                        <input type="text" v-model="name">
                    </div>
                    <div class="inputBox">
                        <span>电话</span>
                        <input type="text" v-model="phone">
                    </div>
                    <div class="inputBox">
                        <span>地区</span>
                        <input type="text" v-model="location">
                    </div>
                    <div class="inputBox">
                        <span>邮箱</span>
                        <input type="text" v-model="email">
                    </div>
                    <div class="inputBoxTwo">
                        <span>留言</span>
                        <textarea name="" id="" cols="30" rows="10" v-model="msg"></textarea>
                    </div>
                    <div class="button" @click="indexKefu">提交</div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { ref, getCurrentInstance, defineComponent } from 'vue';
import { notification } from 'ant-design-vue';

// 右侧导航栏选择的操作:
const contentSelectState = ref(0); // 右侧导航栏的选择
const setContentSelectState = (state) => { // 设置右侧导航栏的选择
    if (state == contentSelectState.value) {
        contentSelectState.value = 0
    } else {
        contentSelectState.value = state
    }
}

// 取消全部状态背景的操作：
const allHidden = ref(false) // 取消状态背景的显示状态
const setAllHidden = (state) => {
    allHidden.value = state
}
const isAllHidden = () => { // 取消右侧导航栏的全部选择
    contentSelectState.value = 0
    setAllHidden(false)
}

// 消息提示框
const openNotificationWithIcon = (type, message, description) => {
    // success
    // info
    // warning
    // error
    notification[type]({
        message,
        description,
        duration: 3
    });
};

// 网络请求
const http = getCurrentInstance().appContext.config.globalProperties.$http // 用于承载网络请求的变量
const name = ref(null); // 姓名
const phone = ref(null); // 电话
const location = ref(null); // 地区
const email = ref(null); // 邮箱
const msg = ref(null); // 留言
const formVer = () => { // 表单验证
    if (name.value == null || !name.value) {
        openNotificationWithIcon('warning', "姓名错误", "请确认填写的姓名格式无误")
        return false
    }
    if (phone.value == null || !phone.value) {
        openNotificationWithIcon('warning', "电话错误", "请确认填写的电话格式无误")
        return false
    }
    if (location.value == null || !location.value) {
        openNotificationWithIcon('warning', "地区错误", "请确认填写的地区格式无误")
        return false
    }
    if (email.value == null || !email.value) {
        openNotificationWithIcon('warning', "邮箱错误", "请确认填写的邮箱格式无误")
        return false
    }
    if (msg.value == null || !msg.value) {
        openNotificationWithIcon('warning', "留言错误", "请确认填写的留言格式无误")
        return false
    }
    return true
}
const indexKefu = () => { // 客服留言
    if (!formVer()) {
        return false
    }
    http.post("/home/index/kefu", {
        name: name.value,
        phone: phone.value,
        location: location.value,
        email: email.value,
        msg: msg.value
    }).then(res => {
        if(res.code == 0){
            openNotificationWithIcon('success',res.msg,res.msg)
            setFormState(false)
            isAllHidden()
        }else{
            openNotificationWithIcon('error',res.msg,res.msg)
        }
    })
}

// 客服显示状态的操作:
const formState = ref(false) // 客服的显示状态
const setFormState = (state) => { // 设置客服的显示状态
    formState.value = state
}

// 阻止冒泡的操作:
const isNull = () => {
    return false
}

// 页面回到顶部的操作:
const topState = ref(true); // 回到顶部是否可用的状态
const goTop = () => { // 回到顶部指令
    if (topState.value) {
        let app = document.querySelector("#app")
        topState.value = false
        goTopTran(app, app.scrollTop); // 通过过渡效果回到顶部
    } else {
        // 当前状态不可点击 回到顶部中...
    }
}
const goTopTran = (element, topNum) => { // 通过过渡效果回到顶部
    // element : 元素
    // topNum : 距离顶部的值
    if (topNum > 0) {
        setTimeout(() => {
            topNum -= 40
            element.scrollTop = topNum
            goTopTran(element, topNum)
        }, .1)
    } else if (topNum == 0) {
        // 样式恢复
        topState.value = true // 恢复点击
        isAllHidden(); // 右侧导航栏的状态恢复
    } else if (topNum < 0) {
        goTopTran(element, 0) // 用0重新调用一遍回调函数
    }
}

</script>

<style scoped lang="scss">
.isShowBack {
    width: calc(100vw - 40px);
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}

.isHiddenBack {
    width: 0;
    height: 0;
    z-index: 0;
}

#rightNav {
    width: 100%;
    height: 100%;

    .contentBox {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .content {
            width: 100%;
            height: 85px;
            border-top: 1px solid #F6F6F8;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            img {
                width: 24px;
                height: 24px;
                transition-duration: .2s;
            }

            span {
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #B8BDCC;
                margin-top: 4px;
                transition-duration: .2s;
            }
        }

        .isContent {
            span {
                color: #C7171E !important;
            }
        }

        .two {
            #wxBox {
                width: 146px;
                height: 137px;
                background: url("@/assets/icon_cebian2@2x.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                position: absolute;
                transition: .2s;
                padding: 9px 17px 9px 9px;

                .code {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .two,
        .three {
            .phoneIsShow {
                opacity: 1;
                left: calc(-146px - 5px);
            }

            .phoneIsHidden {
                opacity: 0;
                left: 146px;
            }
        }

        .three {
            #phoneBox {
                width: 146px;
                height: 40px;
                position: absolute;
                background: url("@/assets/icon_cebian@2x.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                transition: .2s;

                .phone {
                    font-size: 14px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #C7171E;
                    margin-left: 16px;
                }
            }
        }
    }
}

#serverBox {
    width: 100vw;
    height: 100vh;
    background: rgb(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 999;
    justify-content: center;

    .serverFormBox {
        width: 600px;
        height: auto;
        padding-bottom: 40px;
        background: #FFFFFF;
        border: 1px solid #707070;

        .formTitle {
            width: 100%;
            height: auto;
            margin-top: 50px;
            text-align: center;
            font-size: 24px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
        }

        .formTitleTwo {
            width: 100%;
            height: auto;
            margin-top: 20px;
            text-align: center;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
        }

        .formBox {
            width: 100%;
            height: auto;
            margin-top: 40px;

            .inputBox {
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;

                span {
                    font-size: 16px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    margin-right: 24px;
                }

                input {
                    width: 424px;
                    height: 42px;
                    background: #FFFFFF;
                    border: 1px solid #DCDCDC;
                    outline: none;
                }
            }

            .inputBoxTwo {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-bottom: 16px;
                height: auto;

                span {
                    font-size: 16px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    margin-right: 24px;
                }

                textarea {
                    width: 424px;
                    height: 100px;
                    background: #FFFFFF;
                    border: 1px solid #DCDCDC;
                    outline: none;
                    resize: none;
                }
            }

            .button {
                width: 480px;
                height: 42px;
                background: #C7171E;
                margin: 0px auto;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
}
</style>