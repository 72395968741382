import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/homePage.vue') // 首页
  },{
    path: '/product',
    name: 'product',
    component: () => import('../views/product.vue') // 产品与服务
  },{
    path: '/journalism',
    name: 'journalism',
    component: () => import('../views/journalism.vue') // 华锐新闻
  },{
    path: '/journalismMsg',
    name: 'journalismMsg',
    component: () => import('../views/journalismMsg.vue') // 华锐新闻详情
  },{
    path: '/invite',
    name: 'invite',
    component: () => import('../views/invite.vue') // 人才招聘
  },{
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/contactUs.vue') // 联系我们
  }
]
const  scrollBehavior = (to, from, savedPosition) => {
  document.body.scrollTop = 0; 
  document.documentElement.scrollTop = 0; 
  window.pageYOffset = 0; 
  //next() 放行 next('/login') 强制跳转
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁!!
  // next();

}
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
})

export default router
